/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.3
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and scenes
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-transitions * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn,
.navbar-link,
.nav-tabs > li > a,
.nav-tabs > li > a:after,
.nav-pills > li > a,
.nav li > a > .label,
.nav li > a > .badge,
.breadcrumb > li > a,
.breadcrumb-elements > li > a,
.tt-suggestion,
.selectboxit-btn,
.bootstrap-select .btn-default,
.select2-results__option,
.select2-selection__choice__remove,
.dropdown-menu > li > a,
.dropdown-menu > li > label,
.wizard .actions a,
.checker span:after,
.choice span:after,
.selectboxit-option-anchor,
.dt-autofill-list ul li,
.dt-autofill-button .btn,
.dataTable .select-checkbox:before,
.dataTable .select-checkbox:after,
.pika-button,
.sp-input,
.navbar-nav > li > a,
.dropdown-content-footer a,
.icons-list > li > a,
.picker__nav--prev,
.picker__nav--next,
.multiselect.btn-default,
.list-group-item,
.pagination > li > a,
.pagination > li > span,
.pager > li > a,
.pager > li > span,
.datepicker-dropdown .day,
a.label,
a.badge,
.ui-datepicker-buttonpane > button,
.ui-button,
.ui-menu-item,
.ui-selectmenu-button,
.ui-datepicker a,
.media-link,
.menu-list li > a,
.plupload_file_action > a,
.dataTables_paginate .paginate_button,
.dataTables_filter input,
.dt-button,
.picker__list-item,
.picker__day,
.picker__footer,
.sp-replacer,
.sp-cancel,
.sp-choose,
.sp-palette-toggle,
.daterangepicker td,
.daterangepicker th,
.noUi-handle,
.fc-button,
.plupload_button,
.picker__footer button,
.picker__list button,
.AnyTime-btn,
.plupload_filelist li,
.password-indicator-group.input-group-addon,
.password-indicator-label-absolute,
.select2-selection--single:not([class*=bg-]),
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice,
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  -webkit-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  transition: all ease-in-out 0.15s;
}
.close,
.tag [data-role="remove"] {
  -webkit-transition: opacity ease-in-out 0.15s;
  -o-transition: opacity ease-in-out 0.15s;
  transition: opacity ease-in-out 0.15s;
}
.checker span {
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
.table tr,
.table th,
.table td,
.htContextMenu td,
.ranges ul li {
  -webkit-transition: background-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.2
 *  Latest update: Nov 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  background-color: #263238;
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 260px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
  }
}
.sidebar:not(.sidebar-default) + .sidebar:not(.sidebar-default) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar .has-scroll {
  max-height: 192px;
  overflow-y: auto;
}
.sidebar-default {
  background-color: #fff;
  color: #333333;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  .sidebar-default {
    border-bottom: 0;
    border-right: 1px solid #ddd;
  }
  .content-wrapper + .sidebar-default {
    border-left: 1px solid #ddd;
  }
}
.sidebar-content {
  position: relative;
  padding-bottom: 8px;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite,
  .sidebar-mobile-detached .sidebar-detached > .sidebar {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 12px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.sidebar-default .category-title {
  border-bottom-color: #ddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-user + .sidebar-category .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  -webkit-transition: background 0.15s linear, color 0.15s linear;
  -o-transition: background 0.15s linear, color 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li > a .label,
.navigation > li > a .badge {
  -webkit-transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #26A69A;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li.active > a .label,
.navigation > li.active > a .badge {
  background-color: rgba(0, 0, 0, 0.35);
  border-color: transparent;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
  padding-left: 71px;
}
.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
  padding-left: 91px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.navigation .label,
.navigation .badge {
  float: right;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f8f8f8;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation li.active > a > [class*=text-] {
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation li .label-transparent {
  color: #333333;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #f5f5f5;
  color: #333333;
}
.sidebar-default .navigation > li.active > a .label-transparent {
  color: #333333;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
.navigation-lg > li > a,
.navigation-lg > li > a > span {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 48px;
}
.navigation-lg > li ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li > a,
.navigation-sm > li > a > span {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.navigation-xs > li > a,
.navigation-xs > li > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 36px;
}
.navigation-xs > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
@media (min-width: 769px) {
  .sidebar-fixed .sidebar-content {
    position: fixed;
    width: 260px;
    max-height: 100%;
    overflow: auto;
    top: 48px;
    bottom: 48px;
    margin-bottom: -48px;
  }
  .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content {
    width: 56px;
  }
  .sidebar-fixed.sidebar-default .sidebar-content {
    width: 259px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    position: fixed;
    display: block;
    height: 100%;
    z-index: 1001;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
    position: fixed;
  }
  .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    width: 260px;
  }
  .sidebar-fixed-expanded .content-wrapper {
    padding-left: 56px;
  }
}
.sidebar-xs-indicator .sidebar-fixed .sidebar-content {
  top: 0!important;
}
@media (min-width: 769px) {
  .has-detached-left .container-detached {
    float: right;
    margin-left: -260px;
    width: 100%;
  }
  .has-detached-left .content-detached {
    margin-left: 280px;
  }
  .has-detached-left .sidebar-detached {
    float: left;
  }
  .has-detached-right .container-detached {
    float: left;
    margin-right: -260px;
    width: 100%;
  }
  .has-detached-right .content-detached {
    margin-right: 280px;
  }
  .has-detached-right .sidebar-detached {
    float: right;
  }
  .has-detached-right .sidebar-detached.affix {
    right: 20px;
  }
  .sidebar-detached-hidden .container-detached {
    float: none;
    margin: 0;
  }
  .sidebar-detached-hidden .content-detached {
    margin: 0;
  }
  .sidebar-detached-hidden .sidebar-detached {
    float: none;
  }
}
.sidebar-detached .navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .sidebar-detached .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.sidebar-detached.affix {
  position: static;
}
@media (min-width: 769px) {
  .sidebar-detached {
    display: block;
    position: relative;
    margin-bottom: 20px;
  }
  .sidebar-detached > .sidebar-default {
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .sidebar-detached > .sidebar {
    margin-bottom: 0;
    display: block;
    border-radius: 3px;
  }
  .sidebar-detached.affix {
    position: fixed;
    top: 20px;
    bottom: 20px;
    -webkit-transition: bottom ease-in-out 0.15s;
    -o-transition: bottom ease-in-out 0.15s;
    transition: bottom ease-in-out 0.15s;
  }
  .sidebar-detached.affix > .sidebar {
    max-height: 100%;
    overflow-y: auto;
  }
  .sidebar-detached.fixed-sidebar-space {
    bottom: 80px;
  }
  .navbar-bottom .sidebar-detached.fixed-sidebar-space {
    bottom: 86px;
  }
  .navbar-bottom-lg .sidebar-detached.fixed-sidebar-space {
    bottom: 90px;
  }
  .navbar-bottom-sm .sidebar-detached.fixed-sidebar-space {
    bottom: 84px;
  }
  .navbar-bottom-xs .sidebar-detached.fixed-sidebar-space {
    bottom: 82px;
  }
  .navbar-fixed .sidebar-detached {
    top: 86px;
  }
  .navbar-fixed-lg .sidebar-detached {
    top: 90px;
  }
  .navbar-fixed-sm .sidebar-detached {
    top: 84px;
  }
  .navbar-fixed-xs .sidebar-detached {
    top: 82px;
  }
}
.sidebar-separate .sidebar-content {
  padding-bottom: 0;
}
.sidebar-separate .sidebar-content .panel:last-child,
.sidebar-separate .sidebar-content .sidebar-category:last-child {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .sidebar-separate {
    background-color: transparent;
  }
  .sidebar-separate .sidebar-category {
    background-color: #263238;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .sidebar-separate.sidebar-default {
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar-separate.sidebar-default .sidebar-category {
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 56px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .category-title.h6 .icons-list {
    padding-top: 23.538462px;
    padding-bottom: 23.538462px;
  }
  .sidebar-xs .sidebar-main .category-title.h5 .icons-list {
    padding-top: 25.0769236px;
    padding-bottom: 25.0769236px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #26A69A;
    border: 1px solid #26A69A;
    padding: 11px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main .navigation > li > a > span .badge {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: transparent;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation > li .has-ul > span {
    border-radius: 0 3px 0 0;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > a > span {
    display: block;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0 0 3px 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a {
    padding-left: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a:not(.has-ul) {
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user .category-content {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-main .navigation-lg > li > ul {
    top: 48px;
  }
  .sidebar-xs .sidebar-main .navigation-sm > li > ul {
    top: 40px;
  }
  .sidebar-xs .sidebar-main .navigation-xs > li > ul {
    top: 36px;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .badge {
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li:hover:not(.active) > a {
    background-color: #f5f5f5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
.sidebar .nav-tabs {
  border-width: 0 0 1px 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs {
    border-width: 1px;
  }
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #ddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #ddd;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-justified > li:first-child .dropdown-menu:not(.dropdown-menu-right) {
    left: -1px;
  }
  .sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
    right: -1px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .page-container {
      height: 1px;
    }
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
.content {
  padding: 0 20px 60px 20px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.content:first-child {
  padding-top: 20px;
}
body[class*=navbar-bottom] .content {
  padding-bottom: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.layout-boxed {
  /* background: url(../images/backgrounds/boxed_bg.png) repeat; */
}
.layout-boxed > .navbar {
  margin-left: auto;
  margin-right: auto;
}
.layout-boxed .page-container {
  background-color: #f5f5f5;
  margin-left: auto;
  margin-right: auto;
  min-width: 0;
}
@media (min-width: 769px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 769px;
  }
}
@media (min-width: 1025px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1025px;
  }
}
@media (min-width: 1200px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .layout-boxed {
    /* background: url(../images/backgrounds/boxed_bg_retina.png) repeat; */
  }
}
/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.2
*  Latest update: Jan 5, 2017
*
* ---------------------------------------------------------------------------- */
.footer {
  position: absolute;
  bottom: 30px;
}
.footer.navbar {
  left: 0;
  right: 0;
  bottom: 0;
}
.footer.navbar-fixed-bottom {
  z-index: auto;
}
body[class*=navbar-bottom] .footer:not(.navbar) {
  display: none;
}
.footer-boxed {
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation_reverse 1s linear infinite;
  -o-animation: rotation_reverse 1s linear infinite;
  animation: rotation_reverse 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotation_reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-ms-keyframes rotation_reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-360deg);
  }
}
@-o-keyframes rotation_reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-360deg);
  }
}
@keyframes rotation_reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  /* background: url('../images/login_cover.jpg') no-repeat; */
  background-size: cover;
}
.login-container .page-container {
  padding-top: 40px;
  position: static;
}
.page-container .login-form {
  width: 420px;
}
.page-container .login-form .thumb {
  margin: 0 auto 20px auto;
}
.page-container .login-form,
.page-container .registration-form {
  margin: 40px auto 20px auto;
}
@media (max-width: 480px) {
  .page-container .login-form,
  .page-container .registration-form {
    width: 100%;
  }
}
.login-container .page-container .nav-tabs.nav-justified {
  margin-bottom: 0;
}
.login-container .page-container .nav-tabs.nav-justified > li > a {
  border-top: 0!important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
}
.login-container .page-container .nav-tabs.nav-justified > li:first-child > a {
  border-left: 0;
  border-radius: 3px 0 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li:last-child > a {
  border-right: 0;
  border-radius: 0 3px 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li.active > a {
  background-color: transparent;
}
@media (max-width: 768px) {
  .login-container .page-container .nav-tabs.nav-justified {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .login-container .page-container .nav-tabs.nav-justified:before {
    content: none;
  }
  .login-container .page-container .nav-tabs.nav-justified > li > a {
    border-width: 0!important;
  }
}
.footer {
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Error scenes
 *
 *  Styles for error and offline scenes
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 200px;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .error-title {
    font-size: 130px;
  }
}
.offline-title {
  font-size: 160px;
}
@media (max-width: 768px) {
  .offline-title {
    font-size: 90px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.breadcrumb-elements-toggle {
  float: right;
  cursor: pointer;
  line-height: 1;
  margin-top: 12px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  margin-right: 20px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 36px;
  margin-top: -18px;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    position: static;
    margin-top: 0;
    height: auto;
  }
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 14px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible-elements {
    top: 56px;
    background-color: #fff;
  }
}
.panel-footer > .heading-elements {
  position: static;
  margin-top: 0;
  padding-right: 20px;
}
.panel-footer > .heading-elements:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer-bordered > .heading-elements:not(.visible-elements) {
  margin-left: -20px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
    float: none!important;
  }
  .panel-footer .heading-elements-toggle {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
    display: block;
    text-align: center;
  }
}
.heading-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.heading-thumbnails > li {
  position: relative;
  display: inline-block;
  font-size: 13px;
}
.heading-thumbnails > li + li {
  margin-left: 10px;
}
.heading-thumbnails > li img {
  height: auto;
  max-height: 36px;
  max-width: 100%;
  border-radius: 100px;
}
.heading-thumbnails > li .label,
.heading-thumbnails > li .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .label,
.panel-default > .panel-heading .heading-thumbnails > li .badge,
.panel-footer-transparent .heading-thumbnails > li .label,
.panel-footer-transparent .heading-thumbnails > li .badge {
  border-color: #fff;
}
.heading-thumbnails > li .status-mark {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 2px #fcfcfc;
}
.heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark[class*=border-],
.panel-footer-transparent .heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fff;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark,
.panel-footer-transparent .heading-thumbnails > li .status-mark {
  background-color: #fff;
}
.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
  margin-left: 10px;
}
.page-header-inverse .heading-elements .btn-link {
  color: #fff;
}
.heading-elements .list-inline {
  margin-bottom: 0;
}
.heading-elements .breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-elements.panel-nav .nav > li > a {
  text-align: left;
}
.heading-elements .daterange-custom {
  margin-top: 4px;
}
.heading-elements .heading-btn-group {
  font-size: 0;
}
.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
  margin-left: 10px;
}
.heading-elements.visible-elements {
  text-align: center;
}
.heading-elements.visible-elements .heading-text,
.heading-elements.visible-elements .heading-btn,
.heading-elements.visible-elements .heading-btn-group > .btn,
.heading-elements.visible-elements .ui-slider,
.heading-elements.visible-elements .noui-slider,
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills,
.heading-elements.visible-elements .pagination,
.heading-elements.visible-elements .progress,
.heading-elements.visible-elements .icons-list,
.heading-elements.visible-elements .pager,
.heading-elements.visible-elements .breadcrumb,
.heading-elements.visible-elements .daterange-custom,
.heading-elements.visible-elements .heading-thumbnails,
.heading-elements.visible-elements .heading-form .form-group,
.heading-elements.visible-elements > .btn-group {
  margin-top: 20px;
}
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills {
  margin-bottom: 0;
}
.heading-elements.visible-elements .heading-text:not(.label):not(.badge) {
  display: block;
}
.heading-elements.visible-elements .select2-container,
.heading-elements.visible-elements .selectboxit-container,
.heading-elements.visible-elements .selectboxit-options,
.heading-elements.visible-elements .multiselect + .btn-group,
.heading-elements.visible-elements div.bootstrap-select {
  width: 100%!important;
}
.heading-elements.visible-elements .input-group,
.heading-elements.visible-elements .input-group-btn,
.heading-elements.visible-elements .btn-group,
.heading-elements.visible-elements .dropdown,
.heading-elements.visible-elements .dropup {
  position: static;
}
.heading-elements.visible-elements .dropdown-menu:not(.multiselect-container) {
  left: -1px;
  right: -1px;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.heading-elements.visible-elements .multiselect-container {
  left: 0;
  right: 0;
}
.heading-elements:not(.visible-elements) .heading-text,
.heading-elements:not(.visible-elements) .heading-btn,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .nav-tabs,
.heading-elements:not(.visible-elements) .nav-pills,
.heading-elements:not(.visible-elements) .pagination,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .icons-list,
.heading-elements:not(.visible-elements) .breadcrumb,
.heading-elements:not(.visible-elements) .pager,
.heading-elements:not(.visible-elements) .heading-form,
.heading-elements:not(.visible-elements) .daterange-custom,
.heading-elements:not(.visible-elements) .heading-thumbnails,
.heading-elements:not(.visible-elements) > .btn-group {
  float: left;
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .heading-text {
  display: inline-block;
}
.heading-elements:not(.visible-elements) .heading-text + .heading-text {
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-container,
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-options,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .input-group,
.heading-elements:not(.visible-elements) .selectboxit-container .selectboxit-options,
.heading-elements:not(.visible-elements) .uploader,
.heading-elements:not(.visible-elements) .heading-form .form-control {
  width: 220px;
}
.heading-elements:not(.visible-elements) .select-sm,
.heading-elements:not(.visible-elements) .input-sm,
.heading-elements:not(.visible-elements) .input-group-sm,
.heading-elements:not(.visible-elements) .uploader-sm,
.heading-elements:not(.visible-elements) .pagination-sm,
.heading-elements:not(.visible-elements) .pager-sm,
.heading-elements:not(.visible-elements) .selectbox-sm + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-sm,
.heading-elements:not(.visible-elements) .btn-group-sm > .btn {
  margin-top: 1px;
}
.heading-elements:not(.visible-elements) .select-xs,
.heading-elements:not(.visible-elements) .input-xs,
.heading-elements:not(.visible-elements) .input-group-xs,
.heading-elements:not(.visible-elements) .uploader-xs,
.heading-elements:not(.visible-elements) .pagination-xs,
.heading-elements:not(.visible-elements) .pager-xs,
.heading-elements:not(.visible-elements) .selectbox-xs + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-xs,
.heading-elements:not(.visible-elements) .btn-group-xs > .btn {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .btn-float {
  margin-top: -8px;
}
.heading-elements:not(.visible-elements) .btn-float.has-text {
  margin-top: -19.5px;
}
.heading-elements:not(.visible-elements) .btn-float.btn-link {
  margin-top: -14px;
}
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .ui-slider-lg,
.heading-elements:not(.visible-elements) .noui-slider-lg {
  margin-top: 14px;
}
.heading-elements:not(.visible-elements) .ui-slider-sm,
.heading-elements:not(.visible-elements) .noui-slider-sm {
  margin-top: 16px;
}
.heading-elements:not(.visible-elements) .ui-slider-xs,
.heading-elements:not(.visible-elements) .noui-slider-xs {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .progress {
  margin-top: 9px;
}
.heading-elements:not(.visible-elements) .progress-lg {
  margin-top: 7px;
}
.heading-elements:not(.visible-elements) .progress-sm {
  margin-top: 11px;
}
.heading-elements:not(.visible-elements) .progress-xs {
  margin-top: 13px;
}
.heading-elements:not(.visible-elements) .progress-xxs {
  margin-top: 15px;
}
.heading-elements:not(.visible-elements) .progress-micro {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .icons-list {
  margin-top: 10px;
}
.heading-elements:not(.visible-elements) .heading-text {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements).panel-nav > .nav {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-top: -1px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs {
    border-bottom-width: 0;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs.nav-tabs-bottom > li.active > a:after {
    background-color: transparent;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-sm {
    margin-top: 2px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-xs {
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.visible-elements).panel-nav {
    position: static;
    height: auto;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    float: none;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 15px;
  }
  .panel-flat .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 20px;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-left: 0;
  }
  .panel-footer .heading-elements:not(.visible-elements).panel-nav.not-collapsible > .nav {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}
.heading-form .form-group {
  margin-bottom: 0;
}
.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
  margin-bottom: 0;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-inline,
.heading-elements:not(.visible-elements) .heading-form .radio-inline {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-sm {
  margin-top: 3px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-xs {
  margin-top: 4px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group {
  max-width: 220px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group .form-control {
  width: 100%;
  margin-top: 0;
}
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-sm .btn,
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-xs .btn {
  margin-top: 0;
}
@media (min-width: 769px) {
  .heading-form .form-group {
    float: left;
  }
  .heading-form .form-group + .form-group {
    margin-left: 15px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible),
  .breadcrumb-elements:not(.not-collapsible) {
    display: none;
  }
  .heading-elements.visible-elements,
  .breadcrumb-elements.visible-elements {
    display: block;
  }
  .panel .has-visible-elements {
    padding-bottom: 0;
  }
  .panel-flat .panel-heading.has-visible-elements {
    padding-bottom: 20px;
  }
  .panel .visible-elements {
    border-top: 1px solid #ddd;
    padding: 20px;
    padding-top: 0;
  }
  .panel-heading .visible-elements {
    margin: 15px -20px 0 -20px;
    background-color: #fcfcfc;
  }
  .panel-flat .panel-heading .visible-elements {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  .panel-footer .visible-elements {
    margin-top: 7px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-footer-condensed .visible-elements {
    margin-top: 2px;
  }
  .panel-footer-transparent .visible-elements {
    margin-top: 12px;
    background-color: #fff;
  }
  .panel[class*=bg-] .visible-elements,
  .panel-heading[class*=bg-] .visible-elements,
  .panel-primary .visible-elements,
  .panel-danger .visible-elements,
  .panel-success .visible-elements,
  .panel-warning .visible-elements,
  .panel-info .visible-elements {
    border-color: rgba(255, 255, 255, 0.2);
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .visible-elements.breadcrumb-elements.not-collapsible {
    display: none;
  }
  .page-header-content > .visible-elements {
    background-color: #f5f5f5;
    left: 0;
    right: 0;
    border-top: 1px solid #dcdcdc;
    z-index: 1002;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header-default .page-header-content > .visible-elements {
    background-color: #fcfcfc;
    border-color: #ddd;
  }
  .page-header-inverse .page-header-content > .visible-elements {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.2
*  Latest update: Mar 20, 2017
*
* ---------------------------------------------------------------------------- */
.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.opacity-75 {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.opacity-50 {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.opacity-25 {
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.opacity-0 {
  opacity: 0;
  filter: alpha(opacity=0);
}
.no-edge-top {
  top: 0;
}
.no-edge-bottom {
  bottom: 0;
}
.no-edge-left {
  left: 0;
}
.no-edge-right {
  right: 0;
}
@media (min-width: 1200px) {
  .text-right-lg {
    text-align: right;
  }
}
@media (min-width: 1025px) {
  .text-right-md {
    text-align: right;
  }
}
@media (min-width: 769px) {
  .text-right-sm {
    text-align: right;
  }
}
@media (min-width: 480px) {
  .text-right-xs {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 769px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 480px) {
  .pull-right-xs {
    float: right;
  }
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
.valign-baseline {
  vertical-align: baseline;
}
.valign-text-top {
  vertical-align: top;
}
.valign-text-bottom {
  vertical-align: text-bottom;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.display-block,
label.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.content-group-xs {
  margin-bottom: 10px !important;
}
.content-group-sm {
  margin-bottom: 15px !important;
}
.content-group {
  margin-bottom: 20px !important;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.no-border-radius {
  border-radius: 0;
}
.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.border-radius {
  border-radius: 3px;
}
.border-radius-top {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.border-radius-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.border-radius-left {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.border-radius-right {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lg {
  border-width: 2px;
}
.border-top-lg {
  border-top: 2px solid;
}
.border-bottom-lg {
  border-bottom: 2px solid;
}
.border-left-lg {
  border-left: 2px solid;
}
.border-right-lg {
  border-right: 2px solid;
}
.border-xlg {
  border-width: 3px;
}
.border-top-xlg {
  border-top: 3px solid;
}
.border-bottom-xlg {
  border-bottom: 3px solid;
}
.border-left-xlg {
  border-left: 3px solid;
}
.border-right-xlg {
  border-right: 3px solid;
}
.full-width {
  width: 100%;
}
@media (min-width: 769px) {
  .width-200 {
    min-width: 200px;
  }
  .width-250 {
    min-width: 250px;
  }
  .width-300 {
    min-width: 300px;
  }
  .width-350 {
    min-width: 350px;
  }
  .width-400 {
    min-width: 400px;
  }
  .width-450 {
    min-width: 450px;
  }
  .width-500 {
    min-width: 500px;
  }
  .width-550 {
    min-width: 550px;
  }
  .width-600 {
    min-width: 600px;
  }
  .width-650 {
    min-width: 650px;
  }
}
.img-lg {
  width: 44px!important;
  height: 44px!important;
}
.img-md {
  width: 40px!important;
  height: 40px!important;
}
.img-sm {
  width: 36px!important;
  height: 36px!important;
}
.img-xs {
  width: 32px!important;
  height: 32px!important;
}

.validation-error-label, .validation-valid-label {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  color: #F44336;
  position: relative;
}

.dataTables_filter > label > span {
  float: left;
  margin: 8px 15px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 36px;
  padding: 7px 12px;
  padding-right: 36px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.dataTables_filter {
  position: relative;
  display: inline-block;
  margin: 0 0 20px 0px;
}

.dataTables_filter > label:after {
  content: "\f002";
  font-family: 'FontAwesome';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -6px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}

.dataTables_custom_filter {
  position: relative;
  display: inline-block;
  margin: 0 0 -14px 10px;
}

.dataTables_custom_filter input {
  outline: 0;
  padding: 7px 12px;
  padding-right: 36px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.datatable-header {
  border-bottom: 1px solid #ddd;
}

.datatable-header, .datatable-footer {
  padding: 20px 20px 0 20px;
}

.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}

.dataTables_length select{
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 8px 5px;
  margin-left: 10px;  
}

.datatable-footer {
  border-top: 1px solid #bbb;
}

.dataTables_info {
  float: left;
  padding: 8px 0;
  margin-bottom: 20px;
}

.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 7px;
  min-width: 36px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border: 1px solid transparent;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #f5f5f5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbb;
}
.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 12px;
  padding-right: 12px;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455A64;
}

.drowdown-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  padding: 0 3px;
}

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
  /*float: right;*/
}

.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  padding-right: 0px;
  /*float: right;*/
}

.Select-arrow {
  border-color: #555555 transparent transparent;
  border-style: solid;
  border-width: 7px 3px 3px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.tokenfield {
  height: auto;
}
.tokenfield.form-control {
  height: auto;
  padding: 0 0 2px 0;
}
.tokenfield:after {
  content: '';
  display: table;
  clear: both;
}
.tokenfield .token {
  margin: 1px 0 0 2px;
  cursor: default;
  float: left;
  position: relative;
  border-radius: 2px;
}
.tokenfield .token:not([class*=bg-]) {
  background-color: #eee;
  color: #333333;
}
.tokenfield .token:not([class*=bg-]):hover,
.tokenfield .token:not([class*=bg-]):focus {
  background-color: #e1e1e1;
}
.tokenfield .token .token-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 11px;
  padding-right: 30px;
  font-size: 12px;
  line-height: 1.6666667;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.token-label > .Select-clear-zone{
  display: inline !important;
}